@import '../../variables.scss';

.checkboxInput {
  padding: 5px 0;
  .checkbox {
    vertical-align: top;
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 3px;
    border: solid 1px #dddddd;
    background-color: #ffffff;
    text-align: center;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    cursor: pointer;
    &::before {
      font-family: 'Material Icons';
      content: 'check';
      font-size: 0;
      color: #ffffff;
      line-height: 30px;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 65ms;
    }
    &.checked {
      border-color: $primaryColor;
      background-color: $primaryColor;
      &::before {
        font-size: 24px;
      }
    }
  }
  &.small {
    .checkbox {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      margin: 2px 6px 0 0;
      &::before {
        line-height: 18px;
      }
      &.checked {
        &::before {
          font-size: 15px;
        }
      }
    }
  }
  &.aggregatedLeadView {
    .checkbox {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &::before {
        line-height: 9px;
      }
      &.checked {
        &::before {
          font-size: 9px;
        }
      }
      &.checked {
        border-color: #7FB800;
        background-color: #7FB800;
      }
    }
  }
}
