@import '../../_variables.scss';

.uploadingPopup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9999;
  &.hidden {
    display: none;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(221, 221, 221, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    .popupWindow {
      display: flex;
      flex-direction: column;
      background-color: rgb(255, 255, 255);
      width: calc(100% - 36px);
      position: relative;
      max-width: 250px;
      box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px, rgba(0, 0, 0, 0.06) 0 2px 12px, rgba(0, 0, 0, 0.04) 0 8px 14px, rgba(0, 0, 0, 0.02) 0 12px 16px;
      max-height: 100%;
      border-radius: 12px;
      transition: all 300ms ease 0s;
      padding: 16px;
      position: relative;
      .contentContainer {
        .popupMessage {
          font-family: 'Source Sans Pro';
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #222222;
          padding-top: 5px;
        }
        .uploadingIcon {
          display: block;
          margin: 0 auto;
          &.pending {
            animation: lds-dual-ring 1.2s linear infinite;
          }
        }
      }
    }
  }
}
