@import '../../_variables.scss';

.carouselFramePopup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9999;
  &.hidden {
    display: none;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .popupWindow {
      display: flex;
      flex-direction: column;
      background-color: rgb(255, 255, 255);
      width: calc(100% - 36px);
      position: relative;
      max-width: 768px;
      box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px, rgba(0, 0, 0, 0.06) 0 2px 12px, rgba(0, 0, 0, 0.04) 0 8px 14px, rgba(0, 0, 0, 0.02) 0 12px 16px;
      max-height: 100%;
      border-radius: 8px;
      transition: all 300ms ease 0s;
      padding: 20px;
      position: relative;
      .popupTitle {
        padding-right: 40px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.22;
        color: #333333;
        margin-bottom: 25px;
      }
      .closeButton {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 13px;
          background-color: #999999;
          mask: url(/images/icons/close.svg) no-repeat center;
          mask-size: contain;
        }
      }
      img {
        max-width: 100%
      }

      .buttonSlider {
        padding: 15px;
        border-radius: 50%;
        background: #ec7070;
        opacity: 0.7;
        font-size: 20px;
      }

      .awssld__wrapper {
        overflow: auto;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .framePopup {
    .overlay {
      .popupWindow {
        width: 100%;
        border-radius: 0;
        iframe {
          height: calc(100vh - 68px);
        }
      }
    }
  }
}
