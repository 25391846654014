@import '../_variables.scss';

.listElem {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 0 !important;
  .header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eeeeee;
    padding: 25px 19px 16px;
    .updateIndicator {
      margin-left: auto;
      font-size: 14px;
      color: #666666;
      padding-left: 10px;
      &.new {
        color: $primaryColor;
        &::after {
          content: '';
          display: inline-block;
          margin-left: 6px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $primaryColor;
          vertical-align: middle;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    .contentColumn {
      flex-shrink: 0;
      padding: 19px;
      &+.contentColumn {
        border-left: 1px solid #eeeeee;
      }
      &:nth-child(1) {
        width: calc(50% - 60px);
      }
      &:nth-child(2) {
        width: calc(50% - 60px);
      }
      &:nth-child(3) {
        width: 120px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
      }
    }
    .infoBlock {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 0;
      label {
        width: 165px;
        flex-shrink: 0;
        color: #333333;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        align-items: center;
        &::after {
          content: ':';
          margin-left: auto;
          padding: 0 10px;
        }
      }
      .infoValue {
        color: #333333;
        font-weight: 400;
      }
      .selectInput {
        width: 100%;
        padding: 0;
      }
    }
  }
  .page-link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
    span {
      &.clickable {
        cursor: pointer;
        color: $primaryColor;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .actionButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
    &:disabled,
    &:disabled:hover {
      background-color: transparent;
      cursor: not-allowed;
      pointer-events: none;
      .material-icons {
        color: rgba(0, 0, 0, 0.2);
      }
    }
    .material-icons {
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .caseStatus {
    background-color: $primaryColor;
    color: #ffffff;
    height: 19px;
    text-transform: uppercase;
    padding-left: 5px;
    padding-right: 5px;
  }
  .caseSteps {
    margin-left: 6px;
    font-size: 14px;
    color: #666666;
    line-height: 19px;
  }

  .campaignTitle {
    padding: 0 15px;
  }

}

@media all and (max-width: 768px) {
  .listElem {
    .content {
      flex-direction: column;
      .contentColumn:nth-child(n) {
        width: 100%;
        &+.contentColumn {
          border-left: none;
          border-top: 1px solid #eeeeee;
        }
      }
      .infoBlock {
        padding: 6px 0;
        label {
          max-width: 40%;
        }
      }
    }
  }
}
