$primaryColor: #ec7070;
$secondaryColor: #efa96d;
$color1: #74ae3b;
$color2: #adc45b;
$color3: #83cfcb;
$color4: #6e95d7;
$highlightColor: #ffeeea;
$successColor: #74ae3b;
$errorColor: #e41407;

$googleColor: #3086fd;
$facebookColor: #3b5998;
$whatsappColor: #5bcb58;
