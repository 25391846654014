@import '../../_variables.scss';

.payoutBottomControlView {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 16px 25px;
  .buttonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      padding: 7px 22px;
      color: $primaryColor;
      background: transparent;
      border: 1px solid $primaryColor;
      margin-left: 15px;
    }
  }
}

@media all and (max-width: 768px) {
  .payoutBottomControlView {
    .buttonsContainer {
      button {
        padding: 7px 14px;
        margin-left: 4px;
      }
    }
  }
}
