@import '../../_variables.scss';

.selectInput {
  padding: 8px 0;
  .reactSelect {
    .reactSelect__control {
      border-radius: 3px;
      border: solid 1px #dddddd;
    }
    .reactSelect__control--is-focused {
      border-color: $primaryColor;
      box-shadow: none;
      .reactSelect__indicators {
        .reactSelect__dropdown-indicator {
          color: $primaryColor;
        }
      }
    }
    .reactSelect__value-container {
      padding: 0 6px 0 12px;
      min-height: 36px;
      .reactSelect__placeholder {
        font-size: 14px;
        line-height: 20px;
      }
      .reactSelect__single-value {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .reactSelect__placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
    .reactSelect__multi-value {
      border-radius: 2px;
      padding: 5px;
      margin: 2px 4px 2px 0;
    }
    .reactSelect__multi-value__remove {
      margin-left: 2px;
    }
    .reactSelect__menuList {
    }
    .reactSelect__menu {
      border-radius: 4px;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    }
    .reactSelect__menuPortal {
      
    }
    .reactSelect__option {
      clear: both;
      color: #333;
      margin: 0 5px;
      width: calc(100% - 10px);
      display: block;
      padding: 10px 20px;
      font-size: 13px;
      transition: all 150ms linear;
      font-weight: 400;
      line-height: 2;
      white-space: nowrap;
      border-radius: 2px;
      padding-right: 30px;
      &:hover {
        color: #333333;
        background-color: $highlightColor;
      }
    }
    .reactSelect__option--is-focused {
      color: #333333;
      background-color: transparent;
    }
    .reactSelect__option--is-selected {
      color: #ffffff;
      background-color: $primaryColor;
    }
  }
}
