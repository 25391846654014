@import '../../_variables.scss';

.rejectCampaignPopup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9999;
  &.hidden {
    display: none;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .popupWindow {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgb(255, 255, 255);
      width: calc(100% - 36px);
      position: relative;
      max-width: 480px;
      box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px, rgba(0, 0, 0, 0.06) 0 2px 12px, rgba(0, 0, 0, 0.04) 0 8px 14px, rgba(0, 0, 0, 0.02) 0 12px 16px;
      max-height: 100%;
      border-radius: 8px;
      transition: all 300ms ease 0s;
      padding: 30px 20px 25px;
      position: relative;
      .closeButton {
        position: absolute;
        top: 12px;
        right: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 13px;
          background-color: #999999;
          mask: url(/images/icons/close.svg) no-repeat center;
          mask-size: contain;
        }
      }
      .contentContainer {
        width: 100%;
        padding: 20px 0 0;
        .rejectCampaignView {
          .rejectHeader {
            color: #333333;
            font-size: 24px;
            line-height: 32px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            margin-bottom: 0.67em;
            margin-top: 0.67em;
          }
          .rejectMessage {
            color: #333333;
            margin-bottom: 35px;
            margin-top: 25px;
            text-align: center;
            font-size: 13px;
          }
          .textInput {
            padding: 0;
          }
          .inputError {
            position: absolute;
            color: $errorColor;
            font-size: 12px;
            margin: 5px 0 0;
          }
        }
      }
      .popupControls {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
        .popupControl {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 700;
          color: #ffffff;
          height: 40px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $primaryColor;
          text-transform: uppercase;
          cursor: pointer;
          background-color: $primaryColor;
          box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
