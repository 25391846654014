@import '../_variables.scss';

.payoutFormView {
  width: 100%;
  display: flex;
  justify-content: center;
  .formContainer {
    width: 100%;
    max-width: 540px;
    padding: 20px 0;
    position: relative;
  }
  .formHeader {
    font-size: 20px;
    color: #666666;
  }
  .formContent {
    margin: 20px 0 0;
  }
  button {
    margin-top: 20px;
  }
  .balanceInfo {
    display: block;
    font-size: 16px;
    color: #666666;
  }
  .inputContainer {
    label {
      margin-top: 5px;
      display: block;
      font-size: 16px;
      font-weight: 500;
      color: #666666;
    }
    .inputError {
      color: $errorColor;
      font-size: 14px;
      margin: -2px 0 2px;
    }
  }
  .filesContainer {
    position: relative;
    padding: 15px 0 58px;
    margin-bottom: 10px;
    input[type='file'] {
      display: none;
    }
    .addButton {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      color: #ffffff;
      background-color: $primaryColor;
      box-shadow:
        0 3px 5px -1px rgba(0,0,0,0.2),
        0 6px 10px 0 rgba(0,0,0,0.14),
        0 1px 18px 0 rgba(0,0,0,0.12);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .uploadButton {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .fileItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    padding: 5px 0;
    .fileName {
      width: calc(100% - 40px);
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      padding: 10px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.uploaded {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .fileAction {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
      .material-icons {
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }
}
