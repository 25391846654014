@import '../../variables.scss';

.navigationNotifications {
  position: absolute;
  top: 2px;
  right: 52px;
  .notificationsIcon {
    padding: 10px;
    cursor: pointer;
    position: relative;
    i {
      color: #ffffff;
      font-size: 28px;
    }
    .notificationsCount {
      position: absolute;
      top: 5px;
      left: 25px;
      color: #ffffff;
      font-size: 12px;
      line-height: 12px;
      min-width: 20px;
      text-align: center;
      padding: 4px 6px;
      border-radius: 10px;
      background-color: $color3;
    }
  }
  .notificationsBlock {
    z-index: 110;
    text-align: left;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 280px;
    overflow: hidden;
    &::before {
      content:" ";
      border-style: solid;
      border-width: 0 7px 7px 7px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      top: -7px;
      right: 19px;
    }
    .notificationsList {
      max-height: 420px;
      overflow-y: auto;
      .notificationsHeader {
        padding: 10px 16px 8px;
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #666666;
      }
      .notificationsContainer {
        .notificationsLink {
          border-top: 1px solid #eeeeee;
          padding: 12px 16px;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
          color: $primaryColor;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .notificationsPlaceholder {
        padding: 10px 16px 8px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        border-top: 1px solid #eeeeee;
      }
      .notificationItem {
        padding: 16px;
        border-top: 1px solid #eeeeee;
        cursor: pointer;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 650ms;
        &.unread {
          background-color: $highlightColor;
        }
        .notificationContent {
          vertical-align: top;
          display: inline-block;
          width: 100%;
          .notificationBody {
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #666666;
            b {
              font-weight: 600;
            }
          }
          .notificationDate {
            font-size: 10px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #999999;
          }
        }
      }
    }
    
  }
}
