@import '../_variables.scss';

.aggregatedView {

  .aggregatedLeadRow {
    display: flex;
    flex-direction: row;
    .aggregatedLeadColumn {
      width: 50%;
    }
    &.firstRow {
      border-bottom: 1px solid #EEEEEE;
      padding-bottom: 16px;
      .aggregatedLeadLabel {
        padding-top: 0;
      }
    }
  }

  .aggregatedLeadLabel {
    padding-top: 16px;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #222222;
    opacity: 0.5;
  }

  .aggregatedLeadStatus {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    &.pending {
      color: #E6AF23;
    }
    &.pending_approval {
      color: #E6AF23;
    }
    &.published {
      color: rgb(173, 196, 91);
    }
    &.rejected {
      color: #666666;
    }
    &.deleted {
      color: #666666;
    }
    &.draft {
      color: rgb(239, 169, 109);
    }
    &.finished {
      color: #666666;
    }
  }

  .aggregatedLeadHeader {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #222222;
  }

  .aggregatedLeadValue {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    &.todoElement {
      padding: 5px 0 5px 4px;
    }
  }

  .directionSection {
    display: flex;
    justify-content: row;
    padding-top: 12px;
    .directionValue {
      margin-left: 4px;
    }
    .directionLabel {
      font-family: 'Source Sans Pro';
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #EC7070;
    }
    .direction {
      font-family: 'Source Sans Pro';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color:#222222;
    }
    .icon {
      position: relative;
      width: 16px;
      height: 16px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #EC7070;
      }
      &.chat::before {
        mask: url(/images/icons/chat.svg) no-repeat center;
      }
      &.upload::before {
        mask: url(/images/icons/upload.svg) no-repeat center;
      }
    }
  }

  &.aggregatedLead {
    padding-top: 54px;
    .headerView {
      position: fixed;
      z-index: 999;
      padding: 10px 20px;
    }
  }

  .chatViewPulse {
    animation: pulse .8s ease-out forwards;
    animation-iteration-count: 2;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
  }

  .blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
  }

  .itemContent {
    width: 68%;
  }

  .dataForm {
    padding-right: 30px;
  }

  .itemButton {
    width: 30%;
    button {
      float: right;
      width: 210px;
      padding: 8px 15px;
    }
  }

  .item {
    padding-bottom: 2%;
    margin-bottom: 2%;
  }

  .inputsBlock {
    padding-right: 355px;
  }

  .instruction {
    width: 100%;
  }

  .itemContent {
    font-size: 16px;
    color: #333333;
  }

  .chatSidebar {
    position: fixed;
    top: 54px;
    right: 0;
    bottom: 0;
    width: 360px;
    z-index: 10;
    .chatView {
      .chatContainer {
        border-left: 1px solid #dddddd;
        .fundraiserInfo {
          display: none;
        }
      }
    }
  }

  .leadFields {
    margin-top: 25px;
  }

  .left-btn {
    padding: 15px 0 5px;
  }

  .blockSection {
    position: relative;
      background-color: black;
    &+.blockSection {
      margin-top: 25px;
    }
    .sectionTitle {
      padding: 0 0 25px;
      font-size: 14px;
      font-weight: 600;
      color: #999999;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      &::before,
      &::after {
        content: '';
        border-bottom: 1px solid #dddddd;
        width: 50%;
      }
      &::before {
        margin-right: 16px;
      }
      &::after {
        margin-left: 16px;
      }
    }
    &.aggregatedLeadCard {
      width: 600px;
      padding: 20px;
      border-radius: 10px;
      margin-left: auto;
      margin-right: auto;
      &+.aggregatedLeadCard {
        margin-top: 16px;
      }
    }
  }

  .inputsBlock {
    display: flex;
    flex-direction: column;

    .inputContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      &.hidden {
        display: none;
      }
      label {
        width: 20%;
        max-width: 170px;
        display: block;
        font-size: 12px;
        font-weight: 700;
        color: #666666;
        text-transform: uppercase;
        text-align: right;
        padding-right: 15px;
      }
      .inputElem {
        flex: 1 0;
        width: 80%;
        .inputError {
          position: absolute;
          color: $errorColor;
          font-size: 12px;
          margin: -5px 0 0;
        }
      }
    }

    &.docs {
      .inputContainer {
        flex-direction: column;
        label {
          /*width: 100%;
          text-align: left;
          max-width: initial;*/
          display: none;
        }
        .inputElem {
          width: 100%;
        }
      }
    }
  }

  .aggregatedTitle {
    font-size: 20px;
    color: #666666;
    margin-bottom: 20px;
  }
  .buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    button + button {
      margin-top: 15px;
    }
  }
  .sectionTitle {
    padding: 25px 0;
    font-size: 14px;
    font-weight: 600;
    color: #999999;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    &::before,
    &::after {
      content: '';
      border-bottom: 1px solid #dddddd;
      width: 50%;
    }
    &::before {
      margin-right: 16px;
    }
    &::after {
      margin-left: 16px;
    }
  }
  .fieldContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    label {
      width: 20%;
      max-width: 170px;
      display: block;
      font-size: 12px;
      font-weight: 700;
      color: #666666;
      text-transform: uppercase;
      text-align: right;
      padding-right: 15px;
    }
    label + * {
      flex: 1 0;
    }
  }
  .documentsList {
    display: flex;
    flex-direction: column;
    padding: 0 0 15px;
    .documentItem {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #cccccc;
      padding: 12px 8px;
      font-size: 15px;
      color: #333333;
      .documentTime {
        width: 130px;
      }
      .documentName {
        padding: 0 10px;
        flex: 1 0;
        color: $primaryColor;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          text-decoration: underline;
        }
      }
      .documentType {
        width: 90px;
        text-align: right;
      }
    }
  }
}

.bottomTabs {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 15;
  box-shadow:
    0 -1px 5px 0 rgba(0, 0, 0, 0.2),
    0 -1px 3px 0 rgba(0, 0, 0, 0.14),
    0 -2px 3px -1px rgba(0, 0, 0, 0.12);
  .tabsView {
    margin: 0;
    .tabsContainer {
      .tabsList {
        .tabItem {
          width: 100%;
          padding: 14px 0;
          text-align: center;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {

  .aggregatedView {

    .blockSection {
      &.aggregatedLeadCard {
        width: 100%;
        border-radius: 0;
        &+.aggregatedLeadCard {
          margin-top: 8px;
        }
      }
    }

    .blocks {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .item {
      margin-bottom: 20px;
    }

    .inputsBlock {
      padding-right: 0;

      .inputContainer {
        padding: 10px 0 0;
        flex-direction: column;
        label {
          padding-right: 0;
          width: 100%;
          max-width: initial;
          text-align: left;
        }
        .inputElem {
          width: 100%;
        }
      }
    }

    .itemContent {
      width: 100%;
    }

    .itemButton {
      width: 100%;
      margin: 10px;
    }

    padding-right: 0;
    .chatSidebar {
      bottom: 51px;
      border-bottom: 1px solid #dddddd;
      max-width: 100%;
      transform: translate(0%, 0%);
      transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      overflow-y: hidden;
      &.closed {
        transform: translate(100%, 0%);
      }
      .chatView {
        .chatContainer {
          border-left: 1px solid #dddddd;
          .fundraiserInfo {
            display: none;
          }
        }
      }
      &.sidebarWithActiveKeyboard {
        bottom: 0;
      }
    }
    .bottomTabs {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ffffff;
      z-index: 15;
      box-shadow:
        0 -1px 5px 0 rgba(0, 0, 0, 0.2),
        0 -1px 3px 0 rgba(0, 0, 0, 0.14),
        0 -2px 3px -1px rgba(0, 0, 0, 0.12);
      .tabsView {
        margin: 0;
        .tabsContainer {
          .tabsList {
            .tabItem {
              width: 100%;
              padding: 14px 0;
              text-align: center;
            }
          }
        }
      }
      &.top {
        top: 0;
        border-radius: 10px 10px 0px 0px;
        height: 54px;
        z-index: 999999;
      }
    }
    .fieldContainer {
      flex-direction: column;
      label {
        width: 100%;
        max-width: initial;
        padding-right: 0;
        text-align: left;
      }
      .fileList {
        width: 100%;
      }
    }
    .buttonContainer {
      &::before {
        content: none !important;
      }
      &.delete, &.reject {
        position: initial;
      }
    }
    &.aggregatedLead {
      .chatSidebar {
        width: 100% !important;
        bottom: 0 !important;
        top: 0;
        z-index: 9999999;
        .chatView {
          height: calc(100% - 54px);
          .chatContainer {
            border-left: none;
          }
        }
        &.closed {
          transform: translate(0%, 100%);
        }
      }
      .bottomTabs {
        box-shadow: none;
        background-color: transparent;
      }
    }
  }
}
