@import '../../_variables.scss';

.documentsList {
  padding: 0px 0px 25px;
  position: relative;
  input[type='file'] {
    display: none;
  }
  table {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;
    font-size: 15px;
  }
  .listPlaceholder {
    padding: 25px 0px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #999999;
    text-align: center;
  }
  .listContainer {
    .listSelect {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 10px;
      span {
        margin-left: 18px;
        font-size: 15px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .addButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .documentActions {
    display: flex;
    justify-content: space-between;
  }
  .uploadLinks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &.left {
      float: left;
    }
    &.right {
      float: right;
      align-items: flex-end;
    }
    .uploadLink {
      &+.uploadLink {
        margin-left: 10px;
      }
      display: flex;
      align-items: center;
      height: 40px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      color: $primaryColor;
      span {
        margin-right: 5px;
      }
    }
  }

  .documentsUploadLabel {
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
    opacity: 0.5;
    padding-top: 4px;
    text-transform: uppercase;
  }

  .uploadDocumentsButton {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .uploadIcon {
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #FFFFFF;
      mask: url(/images/icons/upload.svg) no-repeat center;
    }
  }

  .docImage {
    height: 200px;
    width: 200px;
    background-size: cover;
    background-position: center;
  }

  button.chowzb {
    box-shadow: none;
    background-color: transparent;
  }

  button.chowzb:hover:enabled {
    box-shadow: none;
    background-color: transparent;
    color: black;
  }

  button.chowzb:focus:enabled {
    box-shadow: none;
    background-color: transparent;
  }

  button.hCVuYx:disabled {
    background-color: transparent;
  }

  button.brcpxa {
    background-color: $primaryColor;
    box-shadow: 0 0 1px 3px $primaryColor;
  }

  button.sc-gKAblj {
    padding:initial;
  }
}

@media all and (max-width: 768px) {
  .documentsList {
    padding-top: 0;
    .addButton {
      bottom: 70px;
    }
    .uploadLinks {
      flex-direction: column;
      .uploadLink {
      }
    }
  }
}
