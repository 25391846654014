.chatListView {
  margin-bottom: -30px;
  .chatListContinaer {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    overflow: hidden;
    max-width: 1000px;
    margin: 0 auto;
    height: 100%;
    .chatListSection {
      width: 280px;
      height: calc(100vh - 120px);
    }
    .chatContentSection {
      flex: 1 0;
      border-left: 1px solid #dddddd;
      width: 100%;
    }
  }
  &.chatSelected {
    height: 100%;
  }
  .searchBox {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #dddddd;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    .searchIcon {
      padding: 8px;
      color: #333333;
    }
    input {
      min-width: 100px;
      border: none;
      flex: 1 0;
      font-size: 15px;
      color: #333333;
    }
  }
  .chatList {
    overflow-y: auto;
    height: calc(100% - 40px);
    .chatItem {
      padding: 8px 15px;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;
      position: relative;
      .chatTitle {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #999999;
        padding-right: 45px;
      }
      .chatMessage {
        font-size: 16px;
        line-height: 20px;
        color: #333333;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .chatTime {
        position: absolute;
        top: 8px;
        right: 15px;
        text-align: right;
        font-size: 12px;
        line-height: 16px;
        color: #666666;
        margin-top: 4px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .chatListView {
    .chatListContinaer {
      .chatListSection {
        width: 100%;
      }
      .chatContentSection {
        display: none;
      }
    }
    &.chatSelected {
      .chatListContinaer {
        .chatListSection {
          display: none;
        }
        .chatContentSection {
          display: block;
        }
      }
    }
  }
}
