@import '../../_variables.scss';

.fileInput {
  padding: 10px 0;
  span, a {
    color: $primaryColor;
    display: block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &+button {
      margin-top: 8px;
    }
  }
  span {
    padding-top: 8px;
  }
  input[type='file'] {
    display: none;
  }
}
