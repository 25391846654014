@import '../_variables.scss';

.objectEditView {
  padding-top: 54px;
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .inputsBlock {
    display: flex;
    flex-direction: column;
    .inputContainer {
      display: flex;
      flex-direction: row;
	  align-items: center;
	  &.hidden {
	    display: none;
	  }
	  label {
	    width: 20%;
	    max-width: 170px;
	    display: block;
	    font-size: 12px;
	    font-weight: 700;
	    color: #666666;
	    text-transform: uppercase;
	    text-align: right;
	    padding-right: 15px;
	  }
	  .inputElem {
	    flex: 1 0;
	    width: 80%;
	    .inputError {
	      position: absolute;
		  color: $errorColor;
		  font-size: 12px;
		  margin: -5px 0 0;
		}
	  }
	}
	.labelTop {
	  label {
	    align-self: start;
	    margin-top: 25px;
	  }
	}
    .labelArea {
      align-self: end;
      margin-top: 10px;
    }
  }

  .right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .dropdown {
    width: 36px;
    padding: 0;
    padding-top: 6px;
    height: 36px;
    border-left: 2px solid;
    text-align: center;
    span {
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }

  .completeStep {
    height: 36px;
  }

	.buttonContainer {
		padding: 15px 0 5px;
		&:not(.left) {
			&::before {
				content: '';
				display: inline-block;
				width: 20%;
				max-width: 170px;
			}
		}
	}
}

.left-btn {
  float: left;
}

.right-btn {
  float: right;
  align-items: flex-end;
}

@media all and (max-width: 768px) {
	.objectEditView {
		.inputsBlock {
			.inputContainer {
				padding: 10px 0 0;
				flex-direction: column;
				label {
					padding-right: 0;
					width: 100%;
					max-width: initial;
					text-align: left;
				}
				.inputElem {
					width: 100%;
				}
			}
		}
		.buttonContainer {
			&::before {
				display: none;
			}
		}
	}

  .labelTop {
    label {
      align-self: start;
      margin-top: 25px;
    }
  }

	.right-btn {
	  float: right;
	}
}
