@import '../_variables.scss';

.uploadFilesView {
  width: 100%;
  display: flex;
  justify-content: center;
  .uploadContainer {
    width: 100%;
    max-width: 540px;
    padding: 20px 0 75px;
    position: relative;
  }
  input[type='file'] {
    display: none;
  }
  .uploadHeader {
    font-size: 18px;
    color: #666666;
  }
  .selectInput {
    padding: 15px 0 5px;
  }
  .typeDescription {
    margin-top: 15px;
    color: #666666;
  }
  .listContainer {
    padding: 15px 0 0;
  }
  .fileItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    padding: 5px 0;
    .fileName {
      width: calc(100% - 40px);
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      padding: 5px 0;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        text-decoration: underline;
      }
    }
    .fileAction {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      .material-icons {
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }
  .addButton {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    color: #ffffff;
    background-color: $primaryColor;
    box-shadow:
      0 3px 5px -1px rgba(0,0,0,0.2),
      0 6px 10px 0 rgba(0,0,0,0.14),
      0 1px 18px 0 rgba(0,0,0,0.12);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploadButton {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
