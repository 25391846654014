@import '../_variables.scss';

.videoRoomView {

  label {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    text-align: center;
    line-height: 32px;
    min-width: 115px;
    &.fixed {
      width: 115px;
      text-align: right;
    }
  }
  .fundraiserInfo {
    width: 100%;
    border-bottom: 1px solid #dddddd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: 20px;
    span {
      color: #333333;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .controlsView {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .controlButton {
      width: 45px;
      height: 45px;
      margin: 5px;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 60px;
        color: #333333;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
    button {
      height: 40px;
      margin-left: 15px;
    }
    label {
      span {
        font-weight: 400;
        display: block;
      }
    }
    .switchInput {
      margin-left: 10px;
    }
  }
  .columnView .fixed {
      width: 300px;
  }
  .media {
    display: flex;
    flex-direction: row;
  }
  .mediaContainer {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #C4C4C4;
    border-radius: 12px;
    margin: 10px;
    video {
      width: 100%;
      border-radius: 12px;
    }
  }
  .languageSelect {
    label {
      font-family: 'Source Sans Pro';
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      text-align: left;
    }
    .selectInput {
      font-family: 'Source Sans Pro';
      padding-top: 4px;
    }
  }
  .videoRoomWindow {
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.28);
    border-radius: 12px;
    max-width: 350px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    .videoRoomTitle {
      font-family: 'Source Sans Pro';
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: #666666;
      padding-bottom: 16px;
    }
    .leftRoomMessage {
      font-family: 'Source Sans Pro';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
    button {
      font-family: 'Source Sans Pro';
      width: 100%;
      text-transform: none;
      margin-top: 8px;
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 117px;
    width: 100%;
    .action {
      width: fit-content;
      margin-left: 12px;
      margin-right: 12px;
      .actionBar {
        display: flex;
        flex-direction: row;
        .dropdownMenu {
          position: absolute;
          width: 20px;
          height: 36px;
          .menuOverlay {
            background-color: transparent !important;
          }
          .buttonElement {
            position: unset;
            width: 20px;
            height: 36px;
          }
          .menuItem {
            margin: 0;
            padding: 3px 10px;
          }
        }
      }
      span {
        font-family: 'Source Sans Pro';
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #666666;
        display: inline-block;
        width: 100%;
      }
      .actionIcon {
        padding: 6px;
        width: 36px;
        background-color: #505557;
        cursor: pointer;
        margin: auto;
        border-radius: 4px;
        &.inactive {
          background-color: #F42827;
        }
        &.active {
          background-color: #811211;
          .icon::before {
            background-color: #F42827 !important;
          }
        }
        &.withDropdownMenu {
          border-radius: 4px 0 0 4px;
        }
      }
      .expandMore {
        position: relative;
        width: 20px;
        height: 36px;
        background-color: #505557;
        border-radius: 0 4px 4px 0;
        border-left: 1px solid rgba(0, 0, 0, .2);
        cursor: pointer;
        display: flex;
        align-items: center;
        &.inactive {
          border-left: 1px solid rgba(255, 255, 255, .2);
          background-color: #F42827;
        }
      }
    }
  }
  .recording {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #F42827;
    border-radius: 12px;
    width: fit-content;
    padding: 4px 12px;
    .circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #FFFFFF;
    }
    span {
      font-family: 'Source Sans Pro';
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      padding-left: 6px;
    }
  }

  .icon {
    position: relative;
    width: 24px;
    height: 24px;
    margin-left: auto;
    margin-right: auto;
    &::before {
      content: '';
      position: absolute;
      background-color: #FFFFFF;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &.more {
      width: 20px;
      height: 20px;
      &::before {
        mask: url(/images/icons/expand_more.svg) no-repeat center;
      }
    }
    &.mic::before {
      mask: url(/images/icons/mic.svg) no-repeat center;
    }
    &.camera::before {
      mask: url(/images/icons/videocam.svg) no-repeat center;
    }
    &.record::before {
      mask: url(/images/icons/radio_button_checked.svg) no-repeat center;
    }
    &.leave::before {
      mask: url(/images/icons/exit_to_app.svg) no-repeat center;
    }
    &.videocam_off::before {
      mask: url(/images/icons/videocam_off.svg) no-repeat center;
    }
    &.mic_off::before {
      mask: url(/images/icons/mic_off.svg) no-repeat center;
    }
  }

}

@media all and (max-width: 768px) {
  .videoRoomView {
    .media {
      flex-direction: column;
      align-items: center;
      .mediaContainer {
        width: 100%;
        margin: 6px;
      }
    }
  }
}
