.rdw-embedded-modal {
  position: fixed !important;
  left: 25px !important;
  margin-right: 0 !important;
  transform: translate(0) !important;
  width: calc(100vw - 415px) !important;
  top: 30% !important;
  box-shadow: 0px 10px 5px #888, 0px -10px 5px #888 !important;
}
.rdw-embedded-modal-header-option {
  width: 100% !important;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: #666666;
}

@media all and (max-width: 768px) {
  .rdw-embedded-modal {
    left: 0px !important;
    width: calc(100vw) !important;
  }
}
