@import '../../variables';

.progressBar {
  height: 4px;
  width: 100%;
  background-color: #EEEEEE;
  border-radius: 10px;

  .filler {
    height: 100%;
    background-color: #7FB800;
    border-radius: 10px;
  }
}
