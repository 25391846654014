@import '../../variables.scss';

.captureAudio {
  --balloon-color: rgba(0, 0, 0, 0.3);
  --balloon-text-color: #FFFFFF;
  --balloon-move: -2px;

  height: auto;
  z-index: 13000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  &.recording {
    padding-left: 20px;
  }
  canvas {
    width: 100%;
    z-index: 999999;
    padding-left: 9px;
  }
  .audioClose {
    position: absolute;
    z-index: 999999;
    transform: scale(1.3);
    top: 32px;
    right: 32px;
    color: transparent;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-left: 8px;
    transition: all 0.15s ease-in-out 0s;
    &:hover {
      transform: scale(1.5);
    }
    svg {
      fill: rgb(38, 38, 39);
    }
  }
  .mic {
    position: relative;
    width: 24px;
    height: 24px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #888888;
      mask: url(/images/icons/mic_black.svg) no-repeat center;
      transition: all 0.15s ease 0s;
    }
    &.aggregatedLeadView {
      &::before {
        mask: url(/images/icons/icon_mic.svg) no-repeat center;
      }
    }
  }
  .startTimer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #ffffff;
      font-size: 140px;
      text-shadow: rgba(0, 0, 0, 0.2) 0 0 20px;
    }
  }
  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    filter: drop-shadow(rgb(0, 0, 0) 0 0 20px);
    opacity: 0.8;
    z-index: 1;
  }
  .audioControls {
    label {
      font-weight: 500;
      font-size: 8px;
      color: rgb(38, 38, 39);
      margin-bottom: 24px;
      text-align: center;
      strong {
        color: #ff0000;
      }
      &.rec {
        font-family: Menlo, monospace;
        color: rgb(227, 73, 28);
        font-size: 16px;
        margin-bottom: 8px;
        &::before {
          content: '';
          background: rgb(229, 82, 38);
          border-radius: 50%;
          margin: 1px 8px 1px 0;
        }
      }
    }
    .recordedControls {
      display: flex;
      .recordedButton {
        cursor: pointer;
        color: white;
        fill: white;
        width: 96px;
        height: 96px;
        letter-spacing: 2px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        font-size: 24px;
        line-height: 32px;
        box-shadow: rgba(0, 0, 0, 0.1) 0 10px 30px 0;
        font-weight: 700;
        flex-shrink: 0;
        border-radius: 50%;
        padding: 0;
        transition: all 0.1s ease-in-out 0s;
        cursor: pointer;
        &::before {
          display: block;
          text-transform: uppercase;
        }
      }
    }
    .recordButton {
      width: 24px;
      height: 24px;
      cursor: pointer;
      pointer-events: initial;
      box-shadow: rgba(0, 0, 0, 0.1) 0 10px 30px 0;
      border-radius: 50%;
      transition: all 0.1s ease-in-out 0s;
      &.recording {
        background-color: #ffffff;
        border-radius: 50%;
        &::before {
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          border-radius: 2px;
          background-color: #ff0000;
          transition: all 0.1s ease-in-out 0s;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .captureAudio {
    canvas {
      width: 100%;
      z-index: 999999;
    }
  }
}
