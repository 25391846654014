@import '../_variables.scss';

.loginView {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  text-align: center;
  background-color: #333333;
  .loginForm {
    display: inline-block;
    width: 100%;
    max-width: 400px;
    padding: calc(40vh - 160px) 25px;
    text-align: left;
    .logo {
      width: 100%;
      height: 51px;
      background-image: url(/giveasia_logo.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 25px;
    }
    label {
      display: block;
      color: #ffffff;
      margin-bottom: 2px;
    }
    input {
      display: block;
      margin-bottom: 7px;
      width: 100%;
      border: none;
      padding: 7px 11px;
      outline-color: $secondaryColor;
      border-radius: 4px;
      &::placeholder {
        color: #F44336;
      }
    }
    button, button:hover {
      width: 100%;
      background-color: $primaryColor;
      color: #ffffff;
      border: none;
      padding: 7px 21px;
      margin: 10px 0;
      outline-color: $secondaryColor;
      text-transform: uppercase;
      border-radius: 4px;
    }
  }
  .socialButtons {
    padding-top: 10px;
    .facebook {
      background-color: $facebookColor;
      &:hover {
        background-color: $facebookColor;
      }
      &::before {
        content: '';
        display: inline-block;
        margin: -1px 3px -4px 0;
        height: 20px;
        width: 20px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(/images/icons/facebook.png);
      }
    }
  }
  .orSeparator {
    margin: 20px 0 25px;
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: #999999;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    &::before,
    &::after {
      content: '';
      flex: 1 0;
      border-bottom: solid 1px #dddddd;
    }
    span {
      padding: 0 21px;
    }
  }
}
