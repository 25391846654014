@import '../_variables.scss';

.headerView {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: $primaryColor;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  .backLink,
  .menuButton {
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #ffffff;
    .material-icons {
      font-size: 28px;
    }
  }
  .pageHeader {
    padding: 0 15px 0 15px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.5px;
    display: inline-block;
    width: calc(100% - 130px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .optionsButton {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .tagItem {
    margin-top: -1px;
    margin-left: 20px;
  }

  .tagsContent {
    background-color: #ffffff;
    color: $primaryColor;
    text-transform: uppercase;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
  }

  .tagsTitle {
    margin-left: 20px;
    text-decoration: none;
    color: inherit;
    display: block;
  }

  .pageTitle {
    text-decoration: none;
    color: inherit;
  }

  .tagsContentContainer {
    display: flex;
    &.withLogo {
      justify-content: center;
      align-items: center;
    }
  }

  .headerLogo {
    width: 59px;
    height: 24px;
    background-image: url(/giveasia_white_logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media all and (max-width: 768px) {
  .headerView {
    padding: 0;
    .tagsContent {
      font-size: 12px;
      margin-left: 5px;
    }

    .pageHeader {
      font-size: 18px;
      padding: 0;
      &.withLogoHeader {
        width: 100%;
      }
    }

    .backLink {
      padding: 0;
    }

    .menuButton {
      padding-right: 0;
    }
  }
}
