@import '../../_variables.scss';

.payeesList {
  padding: 10px 0 18px;
  .listContainer {
    .listItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0;
      .selectInput {
        padding: 0;
        width: 100%;
        margin-right: 8px;
        line-height: 16px;
      }
      .removeButton {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: rgba(0, 0, 0, 0.08);
        }
        .material-icons {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }
  button {
    margin-top: 8px;
  }
}
