@import '../_variables.scss';

.marketingTodoElem {
  display: flex;
  border-bottom: 1px solid #cccccc;
  .listItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;
  }
  input {
    width: 100%;
    border: none;
    font-size: 16px;
  }
  .actionButton {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
    .material-icons {
      color: rgba(0, 0, 0, 0.54);
    }
  }
}
