@import '../../_variables.scss';

.bottomTabView {
  height: 51px;
  background-color: $primaryColor;
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  .tab {
    width: 33%;
    &.left {
      padding-left: 16px;
    }
    &.center {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .tabHeader {
        font-family: 'Source Sans Pro';
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
      }
    }
    &.right {
      display: flex;
      justify-content: flex-end;
      padding-right: 16px;
      .itemCount {
        background: #FFFFFF;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: $primaryColor;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        margin-left: 4px;
      }
    }
    .icon {
      position: relative;
      width: 24px;
      height: 24px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ffffff;
      }
      &.chat::before {
        mask: url(/images/icons/chat.svg) no-repeat center;
      }
      &.close::before {
        background-color: #222222;
        mask: url(/images/icons/close.svg) no-repeat center;
      }
    }
  }
  &.active {
    height: 54px;
    background-color: #FFFFFF;
    margin-right: 0;
    margin-left: 0;
    border-bottom: 1px solid rgba(34, 34, 34, 0.1);
    width: 100%;
    .tab {
      .tabHeader {
        font-family: 'Source Sans Pro';
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #222222;
      }
    }
  }
}
