@import '../_variables.scss';

.sendAgreementPopup {
  .emailNotice {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }

  .emailValidationError {
    color: $errorColor;
    font-size: 14px;
    margin: -4px 0 2px;
  }
}
