html {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
	height: 100%;
	font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.5em;
  padding: 0;
}

* {
	box-sizing: border-box;
	outline: none;
}
