@import '../_variables.scss';

.givikiFormView {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .givikiIntroduction {
    width: 100%;
    min-height: calc(100vh - 80px);
    max-width: 760px;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .givikiImage {
      height: 87px;
      width: 174px;
      background-image: url(/new_giveasia_logo.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .introductionContent {
      margin-top: 40px;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      a {
        color: inherit;
      }
    }
    .introductionTips {
      margin-top: 50px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: #666666;
      text-align: center;
      span {
        display: block;
        margin: 15px 0px;
        color: #999999;
      }
    }
    button {
      margin: 25px 0px 0px;
      padding: 12px 30px;
      font-size: 16px;
    }
  }
  .givikiHeader {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .stepperContainer {
    width: calc(100% - 160px);
    height: 10px;
    border-radius: 5px;
    background-color: rgb(234, 239, 241);
    .stepperIndicator {
      background-color: $primaryColor;
      min-width: 10px;
      height: 10px;
      border-radius: 5px;
      position: relative;
      transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
  .headerButton {
    padding: 15px;
    display: flex;
    cursor: pointer;
    .material-icons {
      color: #666666;
    }
    &.disabled {
      cursor: default;
      .material-icons {
        color: #cccccc;
      }
    }
  }
  .completeMessage {
    width: 100%;
    max-width: 600px;
    padding: 40px 25px;
    text-align: center;
    color: #333333;
    font-size: 18px;
    line-height: 1.4;
  }
  .fieldsContainer {
    width: 100%;
    max-width: 450px;
    padding: 25px 30px;
    display: flex;
    flex-direction: column;
  }
  .nextButton {
    width: 100%;
    margin: 35px 0 25px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.61;
    text-align: center;
    padding: 14px;
    background-color: $primaryColor;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0 10px 10px 0;
    border-radius: 10px;
    &.disabled {
      cursor: default;
      box-shadow: none;
      opacity: 0.5;
    }
  }
  .required {
    color: $primaryColor;
  }
}

@media all and (max-width: 768px) {
  .givikiFormView {
    .givikiIntroduction {
      .introductionContent {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
