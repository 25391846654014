.textInput {
  padding: 8px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #333333;
  &.disabled {
    .textInputContainer {
      background-color: #f5f5f5;
      cursor: default;
    }
  }
  .textInputContainer {
    border: solid 1px #dddddd;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    .prefix,
    .sufix {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      opacity: 0.75;
    }
  }
  &.simplified {
    .textInputContainer {
      border: none;
      border-radius: 0;
      background-color: transparent !important;
    }
    input,
    input[readonly],
    textarea,
    textarea[readonly] {
      font-size: 16px;
      cursor: default !important;
    }
  }
  &.aggregatedLeadView {
    .textInputContainer {
      background-color: transparent;
      border: none;
      padding: 5px 0 5px 4px;
      input {
        font-family: 'Source Sans Pro';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #222222;
      }
    }
  }
  input,
  textarea {
    &[disabled],
    &[readonly] {
      cursor: inherit;
		  background-color: inherit;
    }
    &.centered {
      text-align: center;
    }
    border: none;
    background-color: transparent;
    border-radius: 0px;
    padding: 0px;
    width: 100%;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;
    &::placeholder {
      color: #999999;
    }
    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  textarea {
    resize: none;
    min-height: 100px;
    font-family: inherit;
  }
}
