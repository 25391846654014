@import '../_variables.scss';

.soundBlock {
  max-width: 520px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .label {
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #666666;
    margin-right: 11px;
  }
}

.notificationsView {
  max-width: 520px;
  margin: 13px auto;
  .notificationsList {
    padding: 4px 0 !important;
    .notificationsPlaceholder {
      padding: 10px 16px 8px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
    .notificationItem {
      padding: 16px;
      cursor: pointer;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 650ms;
      &.unread {
        background-color: $highlightColor;
      }
      &:not(:first-child) {
        border-top: 1px solid #eeeeee;
      }
      .notificationContent {
        vertical-align: top;
        display: inline-block;
        width: 100%;

        .notificationId {
          font-size: 14px;
          line-height: 20px;
          color: #666666;
          margin: 2px 0px;
        }
        .notificationBody {
          font-weight: 400;
          font-size: 16px;
          font-style: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: normal;
          color: #333333;
          margin: 2px 0px;
          b {
            font-weight: 600;
          }
        }
        .notificationDate {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #999999;
          margin: 2px 0px;
        }
      }
    }
  }
}
