@import '../../_variables.scss';

.payoutsList {
  padding: 10px 0 15px;
  table {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;
    font-size: 15px;
    thead {
			tr {
				th {
					height: 40px;
					border: none;
					color: #999999;
          font-weight: 400;
          text-align: left;
          padding: 10px;
          height: 41px;
        }
      }
    }
    tbody {
      tr {
        &.rejected {
          background-color: #f5f5f5;
          td {
            color: #bbbbbb;
          }
        }
        td {
          padding: 10px;
          overflow: hidden;
          border-top: 1px solid #dddddd;
          height: 41px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &.control {
            padding: 0 10px;
            overflow: visible;
          }
        }
      }
    }
  }
  a {
    display: block;
    color: $primaryColor;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media all and (max-width: 768px) {
  .payoutsList {
    table {
      border-radius: 4px;
      box-shadow:
        0 1px 3px 0 rgba(0,0,0,0.2),
        0 1px 1px 0 rgba(0,0,0,0.14),
        0 2px 1px -1px rgba(0,0,0,0.12);
      &+table {
        margin-top: 45px;
      }
      &.rejected {
        background-color: #f5f5f5;
        tr {
          td {
            color: #bbbbbb;
          }
        }
      }
      tbody {
        tr {
          &:first-child {
            td {
              border-top: none;
            }
          }
          td {
            &:first-child {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
