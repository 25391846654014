@import '../../_variables.scss';

.tabsView {
  position: relative;
  &.compact {
    .tabsContainer {
      .tabsList {
        .tabItem {
          font-size: 12px;
          padding: 10px 0;
          text-transform: uppercase;
          .material-icons {
            height: 14px;
          }
        }
      }
    }
  }
  .tabsContainer {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .tabsList {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      .tabItem {
        font-size: 18px;
        font-weight: 700;
        color: #666666;
        cursor: pointer;
        padding: 15px 0;
        border-bottom: 3px solid transparent;
        white-space: nowrap;
        z-index: 2;
        &+.tabItem {
          margin-left: 20px;
        }
        &.active {
          color: $primaryColor;
          border-bottom-color: $primaryColor;
        }
        .material-icons {
          color: inherit;
          height: 22px;
          padding: 0 10px;
        }
      }
    }
  }
  .tabsBorder {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: solid 1px #dddddd;
  }
  .itemCount {
    background: $primaryColor;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 4px;
  }
  .tabTitle {
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}
