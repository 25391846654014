@import '../../_variables.scss';

.givikiEditView {
  padding-right: 355px;

  .headerView {
    position: fixed;
    z-index: 999;
    padding: 10px 20px;
  }

  .tabsView {
    margin-bottom: 25px;
  }
  .chatSidebar {
    position: fixed;
    top: 54px;
    right: 0;
    bottom: 0;
    width: 360px;
    z-index: 10;
    .chatView {
      .chatContainer {
        border-left: 1px solid #dddddd;
        .fundraiserInfo {
          display: none;
        }
      }
    }
  }
  .blockSection {
    position: relative;
    &+.blockSection {
      margin-top: 25px;
    }
    .sectionTitle {
      padding: 0 0 25px;
      font-size: 14px;
      font-weight: 600;
      color: #999999;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      &::before,
      &::after {
        content: '';
        border-bottom: 1px solid #dddddd;
        width: 50%;
      }
      &::before {
        margin-right: 16px;
      }
      &::after {
        margin-left: 16px;
      }
    }
  }
  .inputsBlock {
    &.docs {
      .inputContainer {
        flex-direction: column;
        label {
          /*width: 100%;
          text-align: left;
          max-width: initial;*/
          display: none;
        }
        .inputElem {
          width: 100%;
        }
      }
    }
  }
  .fieldContainer {
    padding: 30px 0;
    display: flex;
    flex-direction: row;
    &+.fieldContainer {
      padding-top: 0;
    }
    label {
      width: 20%;
      max-width: 170px;
      display: block;
      font-size: 12px;
      font-weight: 700;
      color: #666666;
      text-transform: uppercase;
      text-align: right;
      padding: 11px 15px 0 0;
    }
    .fileList {
      width: 80%;
      padding-top: 0;
    }
  }
  .fileList {
    width: 100%;
    padding-top: 5px;
    .fileItem {
      width: 100%;
      padding: 10px 0;
      &:not(:first-child) {
        border-top: 1px solid #cccccc;
      }
      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        overflow: hidden;
        white-space: initial;
        text-overflow: ellipsis;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .buttonContainer {
    &.delete {
      position: absolute;
      right: 16px;
      bottom: 20px;
      &::before {
        content: none !important;
      }
    }
    &.reject {
      position: absolute;
      right: 115px;
      bottom: 20px;
      &::before {
        content: none !important;
      }
    }
    &.right {
      display: flex;
      justify-content: center;
      align-items: center;
      .outlineReject {
        margin-top: 0;
        margin-left: 10px;
        padding: 7px 14px;
      }
      &::before {
        width: 50% !important;
      }
    }
    &::before {
      content: '';
      width: 20%;
      max-width: 170px;
      display: block;
    }
  }
}

@media all and (max-width: 768px) {
  .givikiEditView {
    padding-right: 0;
    .chatSidebar {
      bottom: 51px;
      border-bottom: 1px solid #dddddd;
      max-width: 100%;
      transform: translate(0%, 0%);
      transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      overflow-y: hidden;
      &.closed {
        transform: translate(100%, 0%);
      }
      .chatView {
        .chatContainer {
          border-left: 1px solid #dddddd;
          .fundraiserInfo {
            display: none;
          }
        }
      }
      &.sidebarWithActiveKeyboard {
        bottom: 0;
      }
    }
    .bottomTabs {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ffffff;
      z-index: 15;
      box-shadow:
              0 -1px 5px 0 rgba(0, 0, 0, 0.2),
              0 -1px 3px 0 rgba(0, 0, 0, 0.14),
              0 -2px 3px -1px rgba(0, 0, 0, 0.12);
      .tabsView {
        margin: 0;
        .tabsContainer {
          .tabsList {
            .tabItem {
              width: 100%;
              padding: 14px 0;
              text-align: center;
            }
          }
        }
      }
    }
    .fieldContainer {
      flex-direction: column;
      label {
        width: 100%;
        max-width: initial;
        padding-right: 0;
        text-align: left;
      }
      .fileList {
        width: 100%;
      }
    }
    .buttonContainer {
      &::before {
        content: none !important;
      }
    }
  }
}
