@import '../_variables.scss';

.dashboardView {
  .graph {
    padding: 32px 16px 20px;
    border-radius: 4px;
    box-shadow:
        0 1px 3px 0 rgba(0,0,0,0.2),
        0 1px 1px 0 rgba(0,0,0,0.14),
        0 2px 1px -1px rgba(0,0,0,0.12);
    background-color: #ffffff;
    font-size: 15px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    .graphListItem {
      padding: 10px;
    }
    .graphListItem:last-child {
      border: none;
    }
    h4 {
      font-weight: 400;
      color: #999;
      padding: 10px;
      border-bottom: 1px solid #eee;
      margin: 0;
    }
  }
}
