@import '../_variables.scss';

.chatView {
  --balloon-color: rgba(0, 0, 0, 0.3);
  --balloon-text-color: #FFFFFF;
  --balloon-move: 30px;

  input[type='file'] {
    display: none;
  }

  .icon {
    position: relative;
    width: 24px;
    height: 24px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #888888;
      transition: all 0.15s ease 0s;
    }
    &.send::before {
      mask: url(/images/icons/send_black.svg) no-repeat center;
    }

    &.attachFile::before {
      mask: url(/images/icons/attach_file_black.svg) no-repeat center;
    }

    &.videocam::before {
      mask: url(/images/icons/videocam_black.svg) no-repeat center;
    }
  }

  .sendButton, .removeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 8px;
    padding-right: calc(env(safe-area-inset-right, 0px) + 8px);
    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #999999;
      padding-right: 6px;
    }
  }

  .sendButton {
    &.active {
      cursor: pointer;
      .send::before {
        background-color: #7FB800;
      }
      span {
        color: #7FB800;
      }
    }
  }

  .authorContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    display: table;
  }

  .backIcon {
    display: none;
    padding: 8px;
    color: #666666;
    cursor: pointer;
  }

  .authorItem {
    width: calc(100% - 25px);
    height: 30px;
    margin-bottom: 2%;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }

  .item:first-child {
    width: 25px;
    height: 26px;
  }

  .header {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #dddddd;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      color: #333333;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .authorName {
    width: 100%;
    height: 25px;
    margin-top:3px;
    margin-left: 7px;
    text-align: center;
  }

  .messageInfo {
    width: 100%;
  }

  .headerSearch {
    width: 100%;
  }

  .headerActionsButton {
    width: 5%;
    padding-right: 33px;
    padding-right: calc(env(safe-area-inset-right, 0px) + 33px);
  }

  .message {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 30px;
    padding-bottom: calc(env(safe-area-inset-bottom, 0px) + 30px);
  }

  .searchIcon {
    width: 32px;
  }

  .messageTime {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 120%;
    color: #999999;
    width: 100%;

    &.system {
      text-align: center;
    }
  }

  .ownedMessageTime {
    text-align: right;
  }

  .ownedMessageAuthor {
    text-align: right;
  }

  .messageAuthor {
    font-size: 14px;
    line-height: 16px;
    color: #666666;
    span {
      text-align: center;
      vertical-align: middle;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 120%;
      color: #666666;
    }
    .authorAvatar {
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    &.system {
      text-align: center;
    }
  }

  .searchInput {
    width: 100%;
    height: 40px;
    top: 20px;
    background: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    border: 1px solid #AAAAAA;
    border-radius: 4px;
  }

  .messageInput {
    border: 1px solid #AAAAAA;
    margin: 16px;
    width: calc(360px - 32px);
    background-color: #ffffff;
    z-index: 15;
    border-radius: 4px;
    .messageTextarea {
      display: flex;
      justify-content: space-between;
      min-height: 42px;
      border-bottom: 1px solid #DDDDDD;
      width: 100%;
      textarea {
        width: 100%;
        height: 100%;
        padding: 12px;
        font-size: 14px;
        line-height: 19px;
        &.tmp {
          position: absolute;
          left: 0px;
          right: 0px;
          height: 1px;
          background-color: red;
          color: green;
        }
      }
      .actionsColumn {
        display: flex;
      }
    }
  }

  .customScrollBarArea {
    font-family: Arial;
    text-align: left;
    padding: 12px;
    width: 100%;
    left: 770px;
    top: 658px;
    resize: none;
    background: #FFFFFF;
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 12px;
    color: #333333;
    font-style: normal;
    font-size: 14px;
    line-height: 18px !important;
  }

  .anyUser {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-left: calc(env(safe-area-inset-left, 0px) + 8px);
  }

  .fundraiseranyUser {
    display: none;
  }

  .caseManagerChatAction {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-left: calc(env(safe-area-inset-left, 0px) + 8px);
  }

  .fundraisercaseManagerChatAction {
    position: absolute;
    bottom: 0;
    left: 56px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: flex-end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    height: 80px;
    width: 39px;
  }

  .caseManagerChatActionImage {
    height: 24px;
    width: 24px;
    background-size: cover;
    background-position: center;
    background-color: transparent;
    margin-left: 5px;
  }

  .messageInputContainer {
    .customScrollBar {
      margin-left: 40px;
      height: fit-content;
    }
  }

  .recordButton {
    height: 24px;
    width: 24px;
    background-size: cover;
    background-position: center;
    background-color: transparent;
  }

  audio {
    width: 100%;
  }

  .voiceAudio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 8px;
    audio {
      height: 35px;
    }
  }
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.embedded {
    height: 100%;
    .chatContainer {
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      .messageInput {
        border: 1px solid #AAAAAA;
        margin: 16px;
        width: calc(360px - 32px);
        background-color: #ffffff;
        z-index: 15;
        border-radius: 4px;
        .messageTextarea {
          display: flex;
          justify-content: space-between;
          min-height: 42px;
          border-bottom: 1px solid #DDDDDD;
          .actionsColumn {
            display: flex;
          }
        }
      }
    }
  }
  &.chatLists {
    .chatContainer {
      .messageInput {
        position: static;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        border-top: 1px solid #DDDDDD;
      }
      .searchContainer {
        padding: 0;
        width: calc(100% - 40px);
      }
    }
  }
  &.aggregatedLead {
    .header {
      height: 40px;
      .headerSearch {
        .searchContainer {
          padding-left: 0;
          padding-right: 0;
          .searchInput {
            border: none;
            border-bottom: 1px solid rgba(34, 34, 34, 0.1);
            border-radius: 0;
          }
        }
      }
    }
    .icon {
      &.send::before {
        mask: url(/images/icons/icon_send.svg) no-repeat center;
      }
      &.attachFile::before {
        mask: url(/images/icons/icon_attach.svg) no-repeat center;
      }
      &.videocam::before {
        mask: url(/images/icons/icon_cam.svg) no-repeat center;
      }
      &.search::before {
        mask: url(/images/icons/icon_search.svg) no-repeat center;
      }
    }
    .chatContainer {
       .messageInput {
         width: calc(100% - 32px);
       }
       .fundraiserArea {
         margin-left: 0;
       }
    }
  }

  .dataForm {
    width: 100%;
    padding: 16px 16px 13px;
    border-top: 1px solid #DDDDDD;
    .formTitle {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
    }
    .inputContainer {
      margin-top: 11px;
    }
    .textInput {
      padding: 5px 0;
    }
    label {
      font-weight: 700;
      font-size: 13px;
      line-height: 17px;
      color: #333333;
    }
    .inputError {
      color: $errorColor;
      font-size: 14px;
      margin: -4px 0 2px;
    }
    button {
      margin-top: 11px;
      background: #7FB800;
      width: 100%;
    }
  }

  .chatContainer {
    height: 100%;
    width: 100%;
    max-width: 800px;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    .messageInput {
      width: calc(100% - 32px);
    }
    .messages {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 24px 24px;
      .messageContainer {
        padding: 8px 0;
        display: flex;
        width: 100%;
        .messageItem {
          position: relative;
          max-width: 80%;
          width: 339px;
          min-height: 78px;
          word-break: break-word;
          display: inline-block;
          text-align: left;
          padding: 12px 16px;
          font-size: 14px;
          line-height: 1.43;
          color: #333333;
          border-radius: 10px;
          background-color: #eaeff1;
          &.owned {
            background-color: rgba(255, 105, 99, 0.1);
            border-radius: 10px;
            font-style: normal;
            font-size: 14px;
            line-height: 120%;
            color: rgba(0, 0, 0, 0.5);
            min-height: 78px;
            margin-left: auto;
            text-align: left;
          }
          &.system {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
          }
          &.searched {
            background-color: $highlightColor;
          }
          &.focused {
          }
          &.selected {
            background-color: $color3;
          }
          .messageInfo {
            display: flex;
            flex-direction: row;
            padding-bottom: 8px;
            padding-bottom: calc(env(safe-area-inset-bottom, 0px) + 8px);
          }
          .messageContent {
            font-size: 14px;
            color: #333333;
          }
          .fileLink {
            font-size: 15px;
            color: #333333;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    .fileInfo {
      flex: 1 0;
      padding: 10px 15px;
      font-size: 16px;
      color: #333333;
      display: flex;
      align-items: center;
    }

    .messageInputContainer {
      border-top: 1px solid #dddddd;
      position: relative;
      display: flex;
      padding-right: 70px;
      padding-right: calc(env(safe-area-inset-right, 0px) + 70px);
      input[type='file'] {
        display: none;
      }
      .sendButton,
      .actionsButton,
      .removeButton {
        width: 40px;
        padding: 9px;
        cursor: pointer;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: absolute;
        right: 40px;
        top: 0;
        bottom: 0;
        span {
          color: #333333;
        }
      }
      .actionsButton {
        right: 40px;
      }
    }
    .fundraiserInfo {
      width: 100%;
      height: 40px;
      border-bottom: 1px solid #dddddd;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 10px;
      span {
        color: #333333;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .searchContainer {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      padding-left: 19px;
      padding-left: calc(env(safe-area-inset-left, 0px) + 19px);
      padding-right: 19px;
      padding-right: calc(env(safe-area-inset-right, 0px) + 19px);

      .searchIcon {
        width: 39px;
        padding-bottom: 11px;
        padding-bottom: calc(env(safe-area-inset-bottom, 0px) + 11px);
        padding-top: 11px;
        padding-top: calc(env(safe-area-inset-top, 0px) + 11px);
        padding-left: 17.56px;
        padding-left: calc(env(safe-area-inset-left, 0px) + 17.56px);
        color: #666666;
      }
      input {
        min-width: 70px;
        border: none;
        flex: 1 0;
        font-size: 14px;
        color: #333333;
        font-style: normal;
        line-height: 160%;
        padding-left: 15.26px;
        padding-left: calc(env(safe-area-inset-left, 0px) + 15.26px);
        color: #333333;
      }

      input::placeholder {
        padding-top: 3px;
        padding-top: calc(env(safe-area-inset-top, 0px) + 3px);
      }
      .searchControls {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        user-select: none;
        .searchSwitch {
          padding: 8px;
          color: #333333;
          cursor: pointer;
          &.disabled {
            cursor: default;
            color: #999999;
          }
          &:first-child {
            padding-right: 0;
            padding-right: env(safe-area-inset-right, 0);
          }
          &:last-child {
            padding-left: 0;
            padding-left: env(safe-area-inset-left, 0);
          }
        }
      }
      .resultLabel {
        flex-shrink: 0;
        width: 90px;
        padding: 12px;
        font-size: 14px;
        color: #666666;
      }
      .deleteButton {
        padding: 8px;
        margin: 0 4px;
        color: #333333;
        cursor: pointer;
        &.disabled {
          color: #999999;
          cursor: default;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .chatView {
    .backIcon {
      display: block;
    }

    .chatContainer {
      .messageInputContainer {
        padding-right: 50px;
        padding-right: calc(env(safe-area-inset-right, 0px) + 50px);
      }
    }

    .messageInputContainer {
      .fileInfo {
        flex: 1 0;
        padding: 10px 15px;
        font-size: 16px;
        color: #333333;
        display: flex;
        align-items: center;
        margin-left: 90px;
      }
    }

    .headerSearch {
      width: 100%;
    }


    .fundraiseranyUser {
      display: none;
    }

    .fundraisercaseManagerChatAction {
      position: absolute;
      bottom: 0;
      left: 40px;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: flex-end;
      justify-content: flex-end;
      -webkit-box-align: center;
      align-items: center;
      height: 80px;
      width: 29px;
    }

    .audioControls {
      left: 14px;
    }

    .sendButton, .removeButton {
      padding-right: 8px;
      padding-right: calc(env(safe-area-inset-right, 0px) + 8px);
    }

    .customScrollBarArea {
      text-align: left;
      padding: 12px;
      width: 100%;
      left: 770px;
      top: 658px;
      resize: none;
      background: #FFFFFF;
      border: none;
      box-sizing: border-box;
      border-radius: 10px;
      font-size: 12px;
      color: #333333;
      font-style: normal;
      font-size: 14px;
      margin-right: 0;
      line-height: 18px !important;
    }

    .fundraiserArea {
      margin-left: 65px;
    }

    .chatContainer {
      .messages {
        padding: 20px 15px;
        .messageContainer {
          .messageItem {
            max-width: 85%;
            padding: 10px 15px;
            .messageInfo {
              .messageTime {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 120%;
                color: #999999;
                width: 100%;
              }
              &.system {
                text-align: center;
              }
              .messageAuthor {
                font-size: 12px;
                .authorAvatar {
                  height: 28px;
                  width: 28px;
                }
              }
            }
          }
        }
      }
    }
  }
}
