@import '../variables';

.givikiFormInput {
  margin: 15px 0 5px;
  label {
    display: block;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: #999999;
    padding: 0 0 3px 1px;
  }
  .fieldDescription {
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    color: #999999;
    padding: 0 0 6px 1px;
  }
  .fieldError {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    color: $errorColor;
    padding: 4px 0 0 1px;
  }
  .textInput {
    padding: 0;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    .textInputContainer {
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.1) 0 2px 3px 0 inset;
      background-color: #ffffff;
      border-radius: 10px;
      padding: 15px 16px;
      border: 1px solid #dddddd;
    }
  }
  .selectInput {
    padding: 0;
    .reactSelect {
      .reactSelect__control {
        border-radius: 10px;
        padding: 6px 8px;
        background-color: #fff;
        border: 1px solid #dddddd;
        box-shadow: inset 2px 2px 6px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
  .fileInput {
    padding: 0;
  }
}
