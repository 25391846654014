@import '../../variables.scss';

.wysiwygInput {

  iframe {
    width: 560px;
    height: 315px;
  }

  padding: 6px 0;

  .addHeader {
    padding-right: 10px;
  }
  .addQuote {
    padding-right: 10px;
  }

  .wysiwygWrapper {
    border-radius: 3px;
    box-shadow: none;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    overflow: hidden;

    .rdw-image-modal {
      position: fixed;
      left: 25px !important;
      margin-right: 0 !important;
      transform: translate(0) !important;
      width: calc(100vw - 415px);
      height: 60%;
      top: 20%;
      border: 1px solid #dddddd;
      box-shadow: 0px 10px 5px #888, 0px -10px 5px #888;
    }

    .rdw-image-modal-upload-option-label {
      max-width: 100% !important;
      text-align: center !important;
    }

    .rdw-image-modal-upload-option-label {
      width: 100% !important;
    }
    .wysiwygToolbar {
      margin: 0;
      padding: 12px 10px 8px;
      box-shadow: none;
      .rdw-option-wrapper {
        border: none;
        box-shadow: none;
      }
    }
    .wysiwygEditor {
      color: #333333;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      &.disabled {
        background-color: #f5f5f5;
        cursor: default;
      }
      .DraftEditor-root {
        .public-DraftEditorPlaceholder-inner {
          padding: 16px 20px;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #bbbbbb;
        }
        .DraftEditor-editorContainer {
          height: 350px;
          overflow-y: auto;
          .public-DraftEditor-content {
            padding: 16px 20px;
          }
        }
      }
      .public-DraftStyleDefault-block {
        margin: 0 0 12px;

      }
    }
    .rdw-link-modal {
      height: auto;
      label {
        color: #666666;
        font-weight: normal;
      }
      .rdw-link-modal-buttonsection {
        margin: 10px 0 0;
        text-align: right;
        button {
          display: inline-block;
          padding: 3px 14px;
          width: auto;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .rdw-embedded-modal {
    width: 255px;
    height: 200px;
  }

  .rdw-embedded-modal-btn:disabled:hover {
    box-shadow: none;
  }

  .rdw-image-modal, .rdw-embedded-modal {
    width: 265px;
    height: 240px;
    top: 150px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  .rdw-image-mandatory-sign {
    margin: 0;
  }

  .rdw-embedded-modal-link-input {
    border: solid 1px #dddddd;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #333333;
    margin-left: 3px;
  }

  .rdw-embedded-modal-link-input {
    border: solid 1px #dddddd;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #333333;
    margin-left: 3px;
  }

  .rdw-image-modal-size {
    margin-left: -5px;
    display: flex;
    display: block;
    position: absolute;
    bottom: 55px; /* attaches the element to the bottom */
  }

  .rdw-image-modal-header-label-highlighted {
    background: $primaryColor;
    border-bottom: 2px solid $primaryColor;
  }

  .rdw-embedded-modal-size-input  {
    border: solid 1px #dddddd;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100px;
    height: 38px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #333333;
  }

  .rdw-image-modal-size-input {
    border: solid 1px #dddddd;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100px;
    height: 38px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #333333;
  }

  .rdw-image-modal-url-input {
    border: solid 1px #dddddd;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100%;
    height: 38px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #333333;
    margin-left: 5px;
  }

  .rdw-image-modal-url-section {
    width: 100%;
  }

  .rdw-embedded-modal-size-input {
    margin-left: 3px;
  }

  .rdw-embedded-modal-btn-section {
    justify-content: space-between;
    margin-right: 3px;
  }

  .rdw-embedded-modal-btn {
    width: 100px;
    height: 38px;
    border-radius: 3px;
    background-color: #ec7070;
    text-align: center;
    border: none;
    padding: 8px 24px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    text-transform: uppercase;
  }

  .rdw-image-modal-btn {
    width: 100px;
    height: 38px;
    border-radius: 3px;
    background-color: #ec7070;
    text-align: center;
    border: none;
    padding: 8px 24px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    text-transform: uppercase;
  }

  .rdw-link-modal-btn {
    width: 90px;
    height: 38px;
    color: #333333;
    background: #ece9e9;
  }

  .rdw-image-modal-btn-section {
    margin: 0 !important;
    width: 240px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
  }

  .rdw-image-modal-upload-option {
    height: 35vh;
  }

  .rdw-image-imagewrapper {
    img {
      max-width: 100%;
    }
  }

  .rdw-image-modal-upload-option-image-preview {
    height: 100% !important;
    max-height: 100% !important;
  }
}

@media all and (max-width: 768px) {
  .wysiwygInput {
    .wysiwygWrapper {
      .rdw-image-modal {
        position: fixed;
        width: 100vw;
        left: 0px !important;
      }

      .rdw-image-modal-upload-option-label {
        max-width: 95% !important;
      }

      .rdw-image-modal-upload-option-label {
        width: 100% !important;
        margin-top: 0;
      }
    }
  }
}
